// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-painesend-about-js": () => import("./../src/pages/painesend/about.js" /* webpackChunkName: "component---src-pages-painesend-about-js" */),
  "component---src-pages-painesend-contact-index-js": () => import("./../src/pages/painesend/contact/index.js" /* webpackChunkName: "component---src-pages-painesend-contact-index-js" */),
  "component---src-pages-painesend-contact-thanks-js": () => import("./../src/pages/painesend/contact/thanks.js" /* webpackChunkName: "component---src-pages-painesend-contact-thanks-js" */),
  "component---src-pages-painesend-index-js": () => import("./../src/pages/painesend/index.js" /* webpackChunkName: "component---src-pages-painesend-index-js" */),
  "component---src-pages-painesend-our-farms-church-farm-js": () => import("./../src/pages/painesend/our-farms/church-farm.js" /* webpackChunkName: "component---src-pages-painesend-our-farms-church-farm-js" */),
  "component---src-pages-painesend-our-farms-index-js": () => import("./../src/pages/painesend/our-farms/index.js" /* webpackChunkName: "component---src-pages-painesend-our-farms-index-js" */),
  "component---src-pages-painesend-our-farms-new-lodge-farm-js": () => import("./../src/pages/painesend/our-farms/new-lodge-farm.js" /* webpackChunkName: "component---src-pages-painesend-our-farms-new-lodge-farm-js" */),
  "component---src-pages-painesend-our-farms-painesend-farm-js": () => import("./../src/pages/painesend/our-farms/painesend-farm.js" /* webpackChunkName: "component---src-pages-painesend-our-farms-painesend-farm-js" */),
  "component---src-pages-painesend-our-farms-rectory-farm-js": () => import("./../src/pages/painesend/our-farms/rectory-farm.js" /* webpackChunkName: "component---src-pages-painesend-our-farms-rectory-farm-js" */)
}

